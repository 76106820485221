cs.ns("app.ui.composite.abstract.modal");
app.ui.composite.abstract.modal.model = cs.clazz({
    extend:   app.fw.abstract.model,
    dynamics: {},
    protos:   {
        create () {
            this.base();

            cs(this).model({
                "param:title":        { value: "", valid: "string" },
                "event:modalIsShown": { value: false, valid: "boolean", autoreset: true },
                "event:cancelModal":  { value: false, valid: "boolean", autoreset: true },
                "event:acceptModal":  { value: false, valid: "boolean", autoreset: true }
            });
        }
    }
});